<template>
  <div style="margin: 25px">
    <v-row>
      <v-col md="2">
      <createInvoice @done="getAll" /></v-col>
    <v-col md="2">
    <v-btn color="green" dark block  @click="dialog=true"
      >{{ $t('Repricing materials') }}
      </v-btn>
    </v-col>
    </v-row>
    
        
    <v-data-table :dark="$store.state.isDarkMode"
      :headers="headers"
      :loading="loading"
      :items="items"
      class="elevation-1"
      hide-default-footer
      :items-per-page="10"
    >

      <template v-slot:item.actions="{ item }">
        <deleteInvoice :id="item.id" @record-deleted="getAll" />
        <createInvoice @done="getAll" :isNew="false" :invoice="item" />
      </template>
    </v-data-table>
    <v-pagination
      v-if="!loading && items.length"
      @input="getAll"
      v-model="page"
      :length="Math.ceil(total / 10)"
    ></v-pagination>
    <v-row>
      <v-dialog width="500" v-model="dialog">
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h2>
              {{ $t("Repricing materials") }}
            </h2>
          </v-card-title>
          <v-card-actions class="justify-center">
            <v-row>
              <v-col md="6">
            <v-text-field dense :label="$t('date')" outlined type="date"
                          v-model="date"></v-text-field></v-col>
                          <v-col md="2">
            <v-btn
              color="green"
              @click="repricingCost(date)"
              class="white--text"
              depressed
              >{{ $t("submit") }}</v-btn
            ></v-col>
          </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
  
</template>

<script>
import axios from "axios";
import createInvoice from "./createInvoice.vue";
import deleteInvoice from './deleteInvoice.vue';
import i18n from "../../../../i18n";
export default {
  components: { createInvoice, deleteInvoice },
  data() {
    return {
      loading: false,
      dialog: false,
      page: 1,
      total: 1,
      date: null,
      types: [
        {
          label: i18n.t('purchase'),
          value: "PURCHASES",
        },
        {
          label: i18n.t('sales'),
          value: "SALES",
        },
        {
          label: i18n.t('purchase refunds'),
          value: "PURCHASE_REFUNDS",
        },
        {
          label: i18n.t('sales refunds'),
          value: "SALES_REFUNDS",
        },
        {
          label: i18n.t('transportation'),
          value: "TRANSPORTATION",
        },
      ],
      headers: [
        { text: i18n.t('Invoice Type Name'), value: "name" },
        { text: i18n.t('Invoice Type'), value: "typeLabel" },
        { text: "", value: "actions" },
      ],
      items: [],
    };
  },
  methods: {
    async getAll() {
      try {
        this.loading = true;
        const res = await axios.get("/inventories/invoice-types/index", {
          params: {
            size: 10,
            page: this.page,
          },
        });
        this.items = res.data.data.map((el) => ({
          ...el,
          typeLabel: this.types.find((typ) => el.type == typ.value).label,
        }));
        this.total = res.data.meta.total;
      } finally {
        this.loading = false;
      }
    },
    
   async repricingCost(date){
    let params= {
      date: date,
    }
      const res = await axios.put(`inventories/material-cost/re-calculate`,params)
      if(res.status==200){
        this.$Notifications(
              "تم إعادة التكليف  بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
      }
      this.dialog = false
      date= null
    },
  },
  created() {
    this.getAll();
  },
};
</script>

<style scoped></style>
