<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="isNew" color="green" dark class="mb-2" v-bind="attrs" v-on="on" @click="fetchAdd">{{ $t('add invoice type') }}</v-btn>
      <v-btn v-else v-bind="attrs" v-on="on" color="blue" icon @click="fetchData">
        <v-icon class="ml-1">mdi-pen</v-icon></v-btn>
    </template>
    <v-card>
      <v-card-text class="pt-5">
        <v-row>
          <template>
            <v-col md="12">
              <v-autocomplete :label="$t('Invoice Type')" outlined dense color="#757575" item-text="label" item-value="value"
                :items="types" v-model="invoiceData.type"></v-autocomplete>
            </v-col>
            <v-col md="12">
              <v-text-field :label="$t('Invoice Name')" outlined dense color="#757575" v-model="invoiceData.name"></v-text-field>
            </v-col>
            <v-col md="12">
              <v-text-field :label="$t('Current Number')" outlined dense color="#757575"
                v-model="invoiceData.serial"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field :label="$t('Request Number')" outlined dense color="#757575"
                v-model="invoiceData.request_serial"></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field :label="$t('Order Number')" outlined dense color="#757575"
                v-model="invoiceData.order_serial"></v-text-field>
            </v-col>
            <v-col md="12">
              <v-select :label="$t('Default Price')" outlined dense v-model="invoiceData.default_price"
                :items="defaultPrice" item-text="name" item-value="value"></v-select>
            </v-col>
            <v-col md="12">
              <AutoCompleteField :label="$t('Default Account')" outlined dense no-filter v-model="invoiceData.default_account_id"
                endPoint="/accounting/account/auto-complete" returnObject />
            </v-col>
          </template>
          <template v-if="invoiceData.type != 'TRANSPORTATION'">
            <v-col md="6">
              <AutoCompleteField :label="$t('Corresponding Account')" outlined dense no-filter v-model="invoiceData.related_account_id"
                endPoint="/accounting/account/auto-complete" returnObject />
            </v-col>
            <v-col md="6">
              <AutoCompleteField :label="$t('Discount Account')" outlined dense v-model="invoiceData.discount_account_id"
                endPoint="/accounting/account/auto-complete" returnObject />
            </v-col>
            <v-col md="6">
              <AutoCompleteField :label="$t('hr.loan.cash')" outlined dense no-filter v-model="invoiceData.cash_account_id"
                endPoint="/accounting/account/auto-complete" returnObject />
            </v-col>
            <v-col md="6">
              <AutoCompleteField :label="$t('Default Inventory')" outlined dense no-filter v-model="invoiceData.inventory_id"
                endPoint="/inventories/auto-complete" returnObject />
            </v-col>
            <v-col md="12">
              <v-autocomplete :label="$t('Default Payment Methods')" outlined dense color="#757575" item-text="label"
                item-value="value" no-filter :items="paymentMethods"
                v-model="invoiceData.payment_method"></v-autocomplete>
            </v-col>
            <v-col md="6">
              <AutoCompleteField :label="$t('cost center')" outlined dense no-filter v-model="invoiceData.cost_center_id"
                endPoint="/accounting/cost-center/auto-complete" returnObject />
            </v-col>
            <v-col md="6">
              <AutoCompleteField :label="$t('Expense Account')" outlined dense no-filter v-model="invoiceData.expense_account_id"
                endPoint="/accounting/account/auto-complete" returnObject />
            </v-col>
            <v-col md="6">
              <v-autocomplete :label="$t('Tax Code')" outlined dense color="#757575" item-text="code" item-value="id"
                :items="taxes" v-model="invoiceData.tax_id"></v-autocomplete>
            </v-col>
            <v-col md="6">
              <AutoCompleteField :label="$t('Tax Account')" outlined dense no-filter v-model="invoiceData.tax_account_id"
                endPoint="/accounting/account/auto-complete" returnObject />
            </v-col>
          </template>
          <template>
            <v-col class="justify-center py-5" md="12">
              <h3 style="color: var(--bg-color--font);">{{ $t('Invoice fields') }}</h3>
            </v-col>
            <v-row v-if="invoiceData.table_view.length">
              <template v-for="(item, index) in invoiceData.table_view">
                <v-checkbox :dark="$store.state.isDarkMode" :key="index" :label="item.name" class="mx-4 mb-4"
                  v-model="item.value" hide-details :true-value="1" :false-value="0"></v-checkbox>
              </template>
            </v-row>
          </template>
        </v-row>
        <v-card-actions class="d-flex justify-end">
          <v-btn depressed color="blue-grey" class="white--text" @click="createInvoice" :loading="submitting">{{ isNew ?
            $t('add') : $t('edit') }}</v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";
import i18n from "../../../../i18n";
export default {
  components: { AutoCompleteField, },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    invoice: Object,
  },
  data() {
    return {
      dialog: false,
      types: [
        {
          label: i18n.t('purchase'),
          value: "PURCHASES",
        },
        {
          label: i18n.t('sales'),
          value: "SALES",
        },
        {
          label: i18n.t('purchase refunds'),
          value: "PURCHASE_REFUNDS",
        },
        {
          label: i18n.t('sales refunds'),
          value: "SALES_REFUNDS",
        },
        {
          label: i18n.t('transportation'),
          value: "TRANSPORTATION",
        },
      ],
      defaultPrice: [
        {
          name: i18n.t('Cost'),
          value: "cost",
        },
        {
          name: i18n.t('sale price'),
          value: "retail_price",
        },
        {
          name: i18n.t('Wholesale Price'),
          value: "wholesale_price",
        },
        {
          name:  i18n.t('Private Price'),
          value: "private_price",
        },
      ],
      paymentMethods: [
        {
          label: i18n.t('Cash'),
          value: "CASH",
        },
        {
          label: i18n.t('Credit'),
          value: "CREDIT",
        },
        {
          label: i18n.t('Installment'),
          value: "INSTALLMENT",
        },
      ],
      invoiceData: {
        table_view: [],
        default_price: null,
        name: null,
        type: null,
        serial: null,
        request_serial: null,
        order_serial: null,
        related_account_id: null,
        default_account_id: null,
        discount_account_id: null,
        cash_account_id: null,
        inventory_id: null,
        expense_account_id: null,
        payment_method: "CREDIT",
        cost_center_id: null,
        tax_id: null,
        tax_account_id: null,
      },
      taxes: [],
      submitting: false,
    };
  },
  methods: {
    async createInvoice() {
      try {
        this.submitting = true;
        this.invoiceData.related_account_id = this.invoiceData.related_account_id?.id;
        this.invoiceData.default_account_id = this.invoiceData.default_account_id?.id;
        this.invoiceData.discount_account_id = this.invoiceData.discount_account_id?.id;
        this.invoiceData.cash_account_id = this.invoiceData.cash_account_id?.id;
        this.invoiceData.inventory_id = this.invoiceData.inventory_id?.id;
        this.invoiceData.cost_center_id = this.invoiceData.cost_center_id?.id;
        this.invoiceData.expense_account_id = this.invoiceData.expense_account_id?.id;
        this.invoiceData.tax_account_id = this.invoiceData.tax_account_id?.id;
        let payload = {};
        if (this.invoiceData.type == "TRANSPORTATION") {
          payload.type = this.invoiceData.type;
          payload.name = this.invoiceData.name;
          payload.serial = this.invoiceData.serial;
        } else {
          payload = { ...this.invoiceData };
        }
        if (this.isNew) {
          await axios.post("/inventories/invoice-types", payload);
        } else {
          await axios.put(
            `/inventories/invoice-types/${this.invoice.id}`,
            payload
          );
        }
        this.dialog = false;
        this.$emit("done");
      } finally {
        this.submitting = false;
      }
    },
    async fetchAdd() {
      let params = {
        paginated: 'false',
      };
      if(this.returnAbility('tax:index')){
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
      let result = await axios.get(`/inventories/table-view/index?type=INVOICE`);
      this.invoiceData.table_view = result.data.data;
    },
    async fetchData() {
      let params = {
        paginated: 'false',
      };
      if(this.returnAbility('tax:index')){
        let res = await axios.get(`/accounting/tax`, { params });
        this.taxes = res.data.data;
      }
      let response = await axios.get(`/inventories/invoice-types/${this.invoice.id}`);
      this.invoice = response.data.data;
      this.invoiceData = { ...this.invoice };
      if (this.invoice.default_account) {
        this.invoiceData.default_account_id = this.invoice.default_account;
      }
      if (this.invoice.related_account) {
        this.invoiceData.related_account_id = this.invoice.related_account;
      }
      if (this.invoice.discount_account) {
        this.invoiceData.discount_account_id = this.invoice.discount_account;
      }
      if (this.invoice.cash_account) {
        this.invoiceData.cash_account_id = this.invoice.cash_account;
      }
      if (this.invoice.inventory) {
        this.invoiceData.inventory_id = this.invoice.inventory;
      }
      if (this.invoice.cost_center) {
        this.invoiceData.cost_center_id = this.invoice.cost_center;
      }
      if (this.invoice.expense_account) {
        this.invoiceData.expense_account_id = this.invoice.expense_account;
      }
      if (this.invoice.tax_account) {
        this.invoiceData.tax_account_id = this.invoice.tax_account;
      }
    },
  },
};
</script>
<style></style>
